import {firstYearOfBusiness} from '../../constants';
import {Box, Select} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {type SyntheticEvent, useCallback, useMemo} from 'react';
import {range} from 'shared/src/util';

interface MonthPickerProps {
	year: number;
	month: number; // 1-12
	onChange: (newYear: number, newMonth: number) => void;
}

const MonthPicker = ({year, month, onChange}: MonthPickerProps) => {
	const currentYear = DateTime.now().year;
	const validYears = [...Array(currentYear - firstYearOfBusiness + 1).keys()].map(
		(i) => i + firstYearOfBusiness,
	);

	const validMonths = useMemo(() => {
		if (year === DateTime.now().year) {
			return range(1, DateTime.now().month + 1);
		} else {
			return range(1, 13);
		}
	}, [year]);

	const onYearChange = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			onChange(parseInt(event.currentTarget.value, 10), month);
		},
		[month, onChange],
	);

	const onMonthChange = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			onChange(year, parseInt(event.currentTarget.value, 10));
		},
		[year, onChange],
	);

	return (
		<Box display='flex'>
			<Select
				id='month-picker-month'
				name='month-picker-month'
				value={month}
				onChange={onMonthChange}
				mr='space.300'
			>
				{validMonths.map((month) => (
					<option key={month} value={month}>
						{DateTime.fromISO(`${year}-${month.toString().padStart(2, '0')}-01`).toFormat('MMMM')}
					</option>
				))}
			</Select>

			<Select id='month-picker-year' name='month-picker-year' value={year} onChange={onYearChange}>
				{validYears.map((year) => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</Select>
		</Box>
	);
};

export default MonthPicker;
