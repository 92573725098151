import {firstYearOfBusiness} from '../../constants';
import {Select} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {type SyntheticEvent, useCallback} from 'react';

interface YearPickerProps {
	year: number;
	onChange: (newYear: number) => void;
}

const YearPicker = ({year, onChange}: YearPickerProps) => {
	const currentYear = DateTime.now().year;
	const validYears = [...Array(currentYear - firstYearOfBusiness + 1).keys()].map(
		(i) => i + firstYearOfBusiness,
	);

	const onYearChange = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			onChange(parseInt(event.currentTarget.value, 10));
		},
		[onChange],
	);

	return (
		<Select id='year-picker' name='year-picker' value={year} onChange={onYearChange}>
			{validYears.map((year) => (
				<option key={year} value={year}>
					{year}
				</option>
			))}
		</Select>
	);
};

export default YearPicker;
