import {type ReportsContextContent, useReportsContext} from '../../context/reports-context';
import Layout from '../Layout';
import MonthPicker from '../forms/MonthPicker';
import ReportingPeriodPicker from '../forms/ReportingPeriodPicker';
import YearPicker from '../forms/YearPicker';
import {DateTime} from 'luxon';
import {ReactNode} from 'react';

interface ReportsLayoutProps {
	title: ReactNode;
	datePickerType?: 'daily' | 'monthly' | 'yearly';
	children: ReactNode;
}

const ReportsLayout = ({title, datePickerType = 'daily', children}: ReportsLayoutProps) => {
	const {reportingPeriod} = useReportsContext();

	return (
		<Layout>
			<Layout.Header title={title}>
				<ReportsLayoutDatePicker
					datePickerType={datePickerType}
					startDate={reportingPeriod.startDate}
					endDate={reportingPeriod.endDate}
					onChange={reportingPeriod.onChange}
				/>
			</Layout.Header>
			<Layout.Body children={children} />
		</Layout>
	);
};

type ReportsLayoutDatePickerProps = ReportsContextContent['reportingPeriod'] &
	Pick<ReportsLayoutProps, 'datePickerType'>;

const ReportsLayoutDatePicker = ({
	datePickerType,
	startDate,
	endDate,
	onChange,
}: ReportsLayoutDatePickerProps) => {
	if (datePickerType === 'daily') {
		return <ReportingPeriodPicker startDate={startDate} endDate={endDate} onChange={onChange} />;
	} else if (datePickerType === 'monthly') {
		const onDateChange = (newYear: number, newMonth: number) => {
			onChange(
				DateTime.fromISO(`${newYear}-${newMonth.toString().padStart(2, '0')}-01`),
				DateTime.fromISO(`${newYear}-${newMonth.toString().padStart(2, '0')}-01`).endOf('month'),
			);
		};

		let currentYear;
		let currentMonth;

		if (isFullMonth(startDate, endDate)) {
			currentYear = startDate.year;
			currentMonth = startDate.month;
		} else {
			currentYear = DateTime.now().year;
			currentMonth = DateTime.now().month;

			onDateChange(currentYear, currentMonth);
		}

		return <MonthPicker year={currentYear} month={currentMonth} onChange={onDateChange} />;
	} /* if (datePickerType === 'yearly') */ else {
		const onYearChange = (newYear: number) => {
			onChange(DateTime.fromISO(`${newYear}-01-01`), DateTime.fromISO(`${newYear}-12-31`));
		};

		let currentYear;

		if (isFullYear(startDate, endDate)) {
			currentYear = startDate.year;
		} else {
			currentYear = DateTime.now().year;

			onYearChange(currentYear);
		}

		return <YearPicker year={currentYear} onChange={onYearChange} />;
	}
};

const isFullYear = (startDate: DateTime, endDate: DateTime): boolean => {
	return (
		startDate.hasSame(startDate.startOf('year'), 'day') &&
		endDate.hasSame(endDate.endOf('year'), 'day') &&
		startDate.year === endDate.year
	);
};

const isFullMonth = (startDate: DateTime, endDate: DateTime): boolean => {
	return (
		startDate.hasSame(startDate.startOf('month'), 'day') &&
		endDate.hasSame(endDate.endOf('month'), 'day') &&
		startDate.year === endDate.year &&
		startDate.month === endDate.month
	);
};

export default ReportsLayout;
