import {type Tag, gql} from '../graphql';
import {useQuery} from '@apollo/client';

const GET_TAGS = gql(/* GraphQL */ `
	query GetTagsForCommonHook {
		tags {
			id
			name
			color
			internalId
			parentTagId
		}
	}
`);

interface TagsResult {
	tags: Tag[] | null;
	refetch: () => void;
}

export const useTags = (): TagsResult => {
	const {data: {tags} = {}, refetch} = useQuery(GET_TAGS);

	return {
		tags: (tags as Tag[]) ?? null,
		refetch,
	};
};

export const useInternalTag = (internalId: string): Tag | null => {
	const {tags} = useTags();

	return tags?.find((tag) => tag.internalId === internalId) ?? null;
};
