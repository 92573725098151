import Table from './Table';
import {type ReactNode} from 'react';

interface SummaryTableProps {
	head: {
		label: ReactNode;
		value: ReactNode;
	};
	items: Array<{
		id: string;
		label: ReactNode;
		value: ReactNode;
	}>;
}

const SummaryTable = ({head, items}: SummaryTableProps) => {
	return (
		<Table
			id=''
			head={[
				{
					id: 'label',
					content: head.label,
				},
				{
					id: 'value',
					content: head.value,
				},
			]}
			items={items}
			generateRow={(item) => ({
				id: item.id,
				cells: [item.label, item.value],
			})}
		/>
	);
};

export default SummaryTable;
