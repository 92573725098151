import {salesTaxRate} from '../../constants';
import {useReportsContext} from '../../context/reports-context';
import {formatCurrency} from '../../formatters';
import {gql} from '../../graphql';
import {useInternalTag} from '../../graphql/tag-hooks';
import {useTransactions} from '../../graphql/transaction-hooks';
import SummaryTable from '../SummaryTable';
import SalesTable from '../sales/SalesTable';
import TransactionsTable from '../transactions/TransactionsTable';
import ReportsLayout from './ReportsLayout';
import {useQuery} from '@apollo/client';
import {Box, Text} from '@sproutsocial/racine';
import {useMemo, useState} from 'react';
import {getNameForDateRange} from 'shared/src/datetime';

const GET_SALES = gql(/* GraphQL */ `
	query GetSalesForSalesTaxReport($startDate: String!, $endDate: String!) {
		sales(startDate: $startDate, endDate: $endDate) {
			...SalesTableFields
		}
	}
`);

const SalesTaxReport = () => {
	const {reportingPeriod} = useReportsContext();

	const {data: {sales} = {}} = useQuery(GET_SALES, {
		variables: {
			startDate: reportingPeriod.startDate.toISODate()!,
			endDate: reportingPeriod.endDate.toISODate()!,
		},
	});

	const taxesOwed = useMemo(() => {
		const totalSales =
			sales?.reduce((total, sale) => {
				return (
					total +
					sale.items.reduce((taxes, item) => {
						return taxes + (item.faceValue ?? item.receivedAmount);
					}, 0)
				);
			}, 0) ?? 0;

		return totalSales * salesTaxRate;
	}, [sales]);

	const taxesCollected = useMemo(() => {
		return (
			sales?.reduce((total, sale) => {
				return (
					total +
					sale.items.reduce((taxes, item) => {
						return taxes + (item.taxAmount ?? 0);
					}, 0)
				);
			}, 0) ?? 0
		);
	}, [sales]);

	const salesTaxTag = useInternalTag('taxesPaidSales');

	const taxStartDate = reportingPeriod.startDate.startOf('month');
	const taxEndDate = reportingPeriod.endDate.plus({month: 1}).endOf('month');
	const {transactions: taxesPaidTransactions} = useTransactions({
		startDate: taxStartDate,
		endDate: taxEndDate,
		tagIds: [salesTaxTag?.id] as any,
		skip: !salesTaxTag,
	});

	const [selectedTransactionIds, setSelectedTransactionIds] = useState<number[]>([]);
	const selectedTransactions = useMemo(() => {
		return taxesPaidTransactions?.filter((transaction) =>
			selectedTransactionIds.includes(transaction.id),
		);
	}, [taxesPaidTransactions, selectedTransactionIds]);
	const taxesPaid = useMemo(() => {
		return Math.abs(
			selectedTransactions?.reduce((total, transaction) => {
				return total + transaction.amount;
			}, 0) ?? 0,
		);
	}, [selectedTransactions]);

	return (
		<ReportsLayout title='Sales Tax Report' datePickerType='monthly'>
			<Box mb='space.400'>
				<SummaryTable
					head={{
						label: 'Source',
						value: 'Total',
					}}
					items={[
						{
							id: 'due',
							label: (
								<Text fontSize={300} fontWeight='semibold'>
									Balance Due
								</Text>
							),
							value: (
								<Text fontSize={300} fontWeight='semibold'>
									{formatCurrency(taxesOwed - taxesPaid)}
								</Text>
							),
						},
						{
							id: 'owed',
							label: 'Taxes Owed',
							value: formatCurrency(taxesOwed),
						},
						{
							id: 'collected',
							label: 'Taxes Collected',
							value: formatCurrency(taxesCollected),
						},
						{
							id: 'paid',
							label: 'Taxes Paid',
							value: formatCurrency(taxesPaid),
						},
					]}
				/>
			</Box>

			<Box mb='space.400'>
				<SalesTable sales={sales ?? []} />
			</Box>

			<Box display='flex' flexDirection='column' ml='space.300'>
				<Text fontSize='300' fontWeight='semibold' color='text.headline'>
					Sales Taxes Paid ({getNameForDateRange(taxStartDate, taxEndDate)})
				</Text>
				<Text fontSize='200' color='text.subtext'>
					Select the payments that apply for this period
				</Text>
			</Box>

			<TransactionsTable
				id='sales-tax-report-taxes-paid'
				transactions={taxesPaidTransactions ?? []}
				onTransactionsSelected={setSelectedTransactionIds}
				allowEdits={false}
			/>
		</ReportsLayout>
	);
};

export default SalesTaxReport;
