import {useReportsContext} from '../../context/reports-context';
import {gql} from '../../graphql';
import SalesTable from '../sales/SalesTable';
import ReportsLayout from './ReportsLayout';
import {useQuery} from '@apollo/client';
import {formatIsoDate} from 'shared/src/datetime';

const GET_SALES = gql(/* GraphQL */ `
	query GetSalesForReport($startDate: String!, $endDate: String!) {
		sales(startDate: $startDate, endDate: $endDate) {
			...SalesTableFields
		}
	}
`);

const SalesReport = () => {
	const {reportingPeriod} = useReportsContext();

	const {data: {sales} = {}} = useQuery(GET_SALES, {
		variables: {
			startDate: formatIsoDate(reportingPeriod.startDate),
			endDate: formatIsoDate(reportingPeriod.endDate),
		},
	});

	return (
		<ReportsLayout title='Sales Report'>
			<SalesTable sales={sales ?? []} />
		</ReportsLayout>
	);
};

export default SalesReport;
