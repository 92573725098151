import {DateTime} from 'luxon';

export const parseDatabaseDate = (date: Date): DateTime => {
	return DateTime.fromJSDate(date);
};

export const formatDatabaseDate = (date: DateTime): Date => {
	return date.toJSDate();
};

export const parseIsoDate = (date: string): DateTime => {
	return DateTime.fromFormat(date, 'yyyy-MM-dd');
};

export const formatIsoDate = (dateTime: DateTime): string => {
	return dateTime.toFormat('yyyy-MM-dd');
};

export const formatShortDate = (dateTime: DateTime): string => {
	return dateTime.toLocaleString(DateTime.DATE_SHORT);
};

export const formatFullDate = (dateTime: DateTime): string => {
	return dateTime.toLocaleString(DateTime.DATE_FULL);
};

export const getNameForDateRange = (startDate: DateTime, endDate: DateTime): string => {
	if (startDate.hasSame(endDate, 'day')) {
		return startDate.toFormat('MMMM d, yyyy');
	}

	// Whole week
	if (
		startDate.hasSame(endDate, 'week', {useLocaleWeeks: true}) &&
		startDate.hasSame(startDate.startOf('week', {useLocaleWeeks: true}), 'day') &&
		endDate.hasSame(endDate.endOf('week', {useLocaleWeeks: true}), 'day')
	) {
		return `Week of ${startDate.toFormat('MMMM d, yyyy')}`;
	}

	// Whole month
	if (
		startDate.hasSame(endDate, 'month') &&
		startDate.hasSame(startDate.startOf('month'), 'day') &&
		endDate.hasSame(endDate.endOf('month'), 'day')
	) {
		return startDate.toFormat('MMMM yyyy');
	}

	// Single or multiple whole months
	if (
		startDate.hasSame(startDate.startOf('month'), 'day') &&
		endDate.hasSame(endDate.endOf('month'), 'day')
	) {
		// A single month
		if (startDate.hasSame(endDate, 'month')) {
			return startDate.toFormat('MMMM yyyy');
		}

		// All months in the same year
		if (startDate.hasSame(endDate, 'year')) {
			// Whole year vs partial year
			if (
				startDate.hasSame(startDate.startOf('year'), 'day') &&
				endDate.hasSame(endDate.endOf('year'), 'day')
			) {
				return startDate.toFormat('yyyy');
			} else {
				return `${startDate.toFormat('MMMM')} – ${endDate.toFormat('MMMM yyyy')}`;
			}
		}

		// Months across multiple years
		return `${startDate.toFormat('MMMM yyyy')} – ${endDate.toFormat('MMMM yyyy')}`;
	}

	return `${formatShortDate(startDate)} – ${formatShortDate(endDate)}`;
};
